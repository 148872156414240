import { Outlet, useNavigate } from '@tanstack/react-router';
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Badge,
  Button,
  CurrencyPicker,
  DataTable,
  DatePicker,
  Label,
  PageWrapper,
  Skeleton,
  TransactionStatusBadge,
  TransactionStatusPicker,
  TransactionTypePicker,
} from '@agyt/client/shared/ui/components';
import { useUser } from '@agyt/client/web/core/user';
import { useFindTransactions } from '@agyt/client/web/data-access/api';
import {
  TransactionListResponse,
  TransactionResponse,
} from '@agyt/shared/types';
import { LocalDate, Money } from '@agyt/shared/util/common';
import { useFilters } from './transactions-table-filters';
import { transactionRoot } from './router';

function EmptyListView() {
  const { t } = useTranslation('transactions');
  return (
    <section className="mt-5 flex h-[436px] w-full flex-col items-center justify-center rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex max-w-min flex-col items-center justify-center">
        <h2 className="text-nowrap text-xl font-medium text-slate-900">
          {t('empty.title')}
        </h2>
      </div>
    </section>
  );
}

function TableSkeleton() {
  return (
    <div className="mt-5 flex flex-col gap-5">
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
    </div>
  );
}

export function TransactionsPage() {
  const { filters, resetFilters, setFilters } = useFilters(transactionRoot.id);
  const { t } = useTranslation('transactions');
  const navigate = useNavigate({ from: '/transactions/$id' });
  const { locale } = useUser();
  const { data: transactionsData, isFetching } = useFindTransactions(filters);

  const [transactions, setTransactions] = useState<TransactionResponse[]>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  useEffect(() => {
    if (transactionsData) {
      setTransactions(
        transactionsData.data.map((transaction) => ({
          ...transaction,
          credit: '',
          debit: '',
        })),
      );
    }
  }, [transactionsData, t]);

  const columns: ColumnDef<TransactionListResponse>[] = [
    {
      accessorKey: 'createdAt',
      header: t('table.columns.date'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => (
        <div>
          {new LocalDate({
            timestamp: original.createdAt,
            locale,
          }).format(DateTime.DATE_SHORT)}
        </div>
      ),
    },
    {
      accessorKey: 'action',
      header: t('table.columns.action'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        return (
          <div className="flex flex-col">
            <div>
              {t(`values.action.${original.action}`, {
                ns: 'transactions:details',
              })}

              {original.action === 'payment' && original.paymentType && (
                <span>
                  &nbsp;(
                  {t(`values.paymentType.${original.paymentType}`, {
                    ns: 'pay',
                  })}
                  )
                </span>
              )}
            </div>
            {original.action === 'payment' &&
              (
                transactionsData?.meta as {
                  beneficiaries: { [key: string]: string };
                }
              )?.beneficiaries[original.relatedEntityId] && (
                <div className="text-slate-500">
                  {t('labels.to')}:&nbsp;
                  {
                    (
                      transactionsData?.meta as {
                        beneficiaries: { [key: string]: string };
                      }
                    )?.beneficiaries[original.relatedEntityId]
                  }
                </div>
              )}
          </div>
        );
      },
    },
    {
      accessorKey: 'relatedEntityShortReference',
      header: t('table.columns.reference'),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'credit',
      header: t('table.columns.credit'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        if (original.type === 'credit') {
          const converted = Number(original.amount);
          const credit = new Money({
            amount: !isNaN(converted) ? converted : 0,
            currency: original.currency,
            locale,
          }).format();
          return <div>{credit}</div>;
        }
        return '';
      },
    },
    {
      accessorKey: 'debit',
      header: t('table.columns.debit'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        if (original.type === 'debit') {
          const converted = Number(original.amount);
          const debit = new Money({
            amount: !isNaN(converted) ? converted : 0,
            currency: original.currency,
            locale,
          }).format();
          return <div>{debit}</div>;
        }
        return '';
      },
    },
    {
      accessorKey: 'balanceAmount',
      header: t('table.columns.balance'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        const converted = Number(original.balanceAmount);
        const balance = new Money({
          amount: !isNaN(converted) ? converted : 0,
          currency: original.currency,
          locale,
        }).format();
        return <div>{balance}</div>;
      },
    },
    {
      accessorKey: 'status',
      header: t('table.columns.status'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => (
        <TransactionStatusBadge status={original.status} />
      ),
    },
  ];

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    state: {
      columnFilters,
      globalFilter,
    },
  });

  return (
    <PageWrapper>
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{t('title')}</h1>
          <span>
            {isFetching ? (
              <Skeleton className="h-6 w-8" />
            ) : (
              !!transactionsData?.data?.length && (
                <Badge className="h-6 rounded-sm py-0.5">
                  {transactionsData?.data.length}
                </Badge>
              )
            )}
          </span>
        </div>
      </header>
      <div className="mt-10">
        <section className="mb-6 grid grid-cols-5 items-end gap-4">
          <div className="col-span-2 grid w-full items-center gap-1.5">
            <div className="flex w-full justify-between">
              <Label>{t('labels.date')}</Label>
              <Button
                className="h-5 !px-1"
                variant="ghost"
                onClick={() => {
                  setFilters({
                    createdAtFrom: undefined,
                    createdAtTo: undefined,
                  });
                }}
              >
                {t('buttons.reset')}
              </Button>
            </div>
            <div className="flex w-full gap-2">
              <DatePicker
                placeholder={t('labels.from')}
                date={filters?.createdAtFrom}
                onSelect={(date) =>
                  date &&
                  setFilters({
                    createdAtFrom: new LocalDate({
                      timestamp: date,
                      locale,
                    }).toISO(),
                  })
                }
              />
              <DatePicker
                placeholder={t('labels.to')}
                date={filters?.createdAtTo}
                onSelect={(date) =>
                  date &&
                  setFilters({
                    createdAtTo: new LocalDate({
                      timestamp: date,
                      locale,
                    })
                      .manipulate((dt) => dt.endOf('day'))
                      .toISO(),
                  })
                }
              />
            </div>
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label>{t('labels.transactionType')}</Label>
            <TransactionTypePicker
              value={filters?.action}
              onChange={(value) => {
                setFilters({ action: value?.value });
              }}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label>{t('labels.status')}</Label>
            <TransactionStatusPicker
              value={filters?.status}
              onChange={(value) => {
                setFilters({ status: value?.value });
              }}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label>{t('labels.currency')}</Label>
            <CurrencyPicker
              value={filters?.currency}
              onChange={(value) => {
                setFilters({ currency: value?.value });
              }}
            />
          </div>
        </section>
        <section className="flex items-center gap-4">
          <Button variant="subtleDark" onClick={() => console.log('')}>
            {t('buttons.export')}
          </Button>
          <Button variant="subtleDark" onClick={() => console.log('')}>
            {t('buttons.downloadStatement')}
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              resetFilters();
            }}
          >
            {t('buttons.clear')}
          </Button>
        </section>
        <section className="mt-7 flex w-full items-center justify-end">
          {isFetching ? (
            <Skeleton className="h-5 w-32" />
          ) : (
            <p className="text-sm text-slate-500">
              {t('labels.showing')}{' '}
              {t('labels.results.resultWithCount', {
                count: transactionsData?.data?.length,
              })}
            </p>
          )}
        </section>

        {isFetching ? (
          <TableSkeleton />
        ) : !transactionsData?.data?.length ? (
          <EmptyListView />
        ) : (
          <DataTable<TransactionResponse>
            cellClassName="h-16 p-2"
            table={table}
            onRowClick={({ id }) => {
              navigate({
                to: '/transactions/$id',
                params: { id },
              });
            }}
          />
        )}

        <Outlet />
      </div>
    </PageWrapper>
  );
}

export default TransactionsPage;
