import { IconCheck, IconChevronDown } from '@agyt/client/shared/ui/icons';
import * as React from 'react';

import { cn } from '@agyt/client/shared/util/theme';
import { Button } from './button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export type ComboboxItem = {
  icon?: string;
  value: string;
  label: string;
};

export type ComboboxProps<T> = {
  items: ComboboxItem[];
  label: string;
  emptyLabel: string;
  placeholder: string;
  selectedItem?: ComboboxItem;
  onSelect: (item: T | undefined) => void;
  searchByLabel?: boolean;
  resultClassName?: string;
  className?: string;
  disableSearch?: boolean;
};

export function Combobox<T>({
  items,
  label,
  emptyLabel,
  placeholder,
  selectedItem,
  onSelect,
  searchByLabel,
  resultClassName,
  className,
  disableSearch,
}: ComboboxProps<T>) {
  const [open, setOpen] = React.useState(false);
  const selectedItemData = selectedItem?.value
    ? items.find((item) => item.value === selectedItem?.value)
    : null;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('w-full justify-between', className)}
        >
          {selectedItemData ? (
            <div className="flex items-center gap-3">
              {selectedItemData.icon && (
                <img
                  src={selectedItemData.icon}
                  alt={selectedItemData.label}
                  className="h-4 w-4"
                />
              )}
              {selectedItemData.label}
            </div>
          ) : (
            label
          )}
          <IconChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="popover-content-width p-0">
        <Command>
          {!disableSearch && <CommandInput placeholder={placeholder} />}
          <CommandList className={resultClassName}>
            <CommandEmpty>{emptyLabel}</CommandEmpty>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  keywords={searchByLabel ? [item.label] : undefined}
                  onSelect={() => {
                    setOpen(false);
                    onSelect(item === selectedItem ? undefined : (item as T));
                  }}
                >
                  <IconCheck
                    className={cn(
                      'mr-2 h-4 w-4',
                      selectedItem?.value === item.value
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                  {item.icon && (
                    <img
                      src={item.icon}
                      className="mr-3 h-5 w-5"
                      alt={item.value}
                    />
                  )}
                  <span className="overflow-hidden text-ellipsis">
                    {item.label}
                  </span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
