import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';
import { Button } from './button';
import { SupportedCurrency } from '@agyt/shared/util/common';

export function AccountCard({
  name,
  balance,
  children,
  currency,
}: {
  name: string;
  balance: string;
  currency: SupportedCurrency;
  children: React.ReactNode;
}) {
  const { t } = useTranslation('common');
  return (
    <div className="flex h-[179px] min-w-[363px] rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex h-full flex-col">
        <Link to="/transactions" search={{ currency }}>
          <div className="mb-4 text-base leading-7 text-slate-500">{name}</div>
        </Link>
        <div className="text-xl font-medium text-slate-900">{balance}</div>
        <div className="flex flex-1 items-end gap-3">
          <Button variant="subtle" size="sm">
            <Link to="/pay">{t('actions.pay')}</Link>
          </Button>
          <Button variant="subtle" size="sm">
            <Link to="/currency-exchange">{t('actions.exchange')}</Link>
          </Button>
        </div>
      </div>
      <div className="flex flex-1 items-start justify-end">{children}</div>
    </div>
  );
}

export default AccountCard;
