import { DateTime } from 'luxon';

type LocalDateOptions = {
  timestamp: string; // ISO8601
  locale: string;
};

export class LocalDate {
  private datetime: DateTime;
  private readonly locale: string;

  constructor({ timestamp, locale }: LocalDateOptions) {
    this.datetime = DateTime.fromISO(timestamp, { setZone: true });
    this.locale = locale;
  }

  toISO() {
    return this.datetime.toISO() as string;
  }

  manipulate(cb: (dt: DateTime) => DateTime) {
    this.datetime = cb(this.datetime);
    return this;
  }

  format(localeString = DateTime.DATETIME_SHORT) {
    // Currently just show the datetime in local zone, we'll expand this further in the future to support user preferences for timezone
    const localZoneName = DateTime.local().zoneName;

    return this.datetime
      .setLocale(this.locale)
      .setZone(localZoneName)
      .toLocaleString(localeString);
  }
}

export class UTCDate {
  private readonly datetime: DateTime;

  constructor(timestamp: string) {
    this.datetime = DateTime.fromISO(timestamp, { setZone: true, zone: 'utc' });
  }

  format() {
    return this.datetime.toUTC().toISODate();
  }
}
