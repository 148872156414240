import { CcTransactionStatus } from '@agyt/shared/types';
import { Combobox, ComboboxItem } from './combobox';
import { useTranslation } from 'react-i18next';

export interface TransactionStatus extends ComboboxItem {
  value: CcTransactionStatus;
  label: string;
}

export interface TransactionStatusPickerProps {
  value?: string;
  onChange: (value: TransactionStatus | undefined) => void;
}

export function TransactionStatusPicker({
  value,
  onChange,
}: TransactionStatusPickerProps) {
  const { t } = useTranslation('common');

  const statuses = [
    {
      value: 'pending',
      label: t('values.status.pending', { ns: 'transactions:details' }),
    },
    {
      value: 'completed',
      label: t('values.status.completed', { ns: 'transactions:details' }),
    },
    {
      value: 'deleted',
      label: t('values.status.deleted', { ns: 'transactions:details' }),
    },
  ];

  return (
    <Combobox<TransactionStatus>
      items={statuses}
      label={t('transactionStatus.label')}
      emptyLabel={t('transactionStatus.empty')}
      placeholder={t('transactionStatus.placeholder')}
      selectedItem={statuses.find((status) => status.value === value)}
      onSelect={onChange}
      disableSearch
    />
  );
}

export default TransactionStatusPicker;
