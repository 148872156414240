import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from '@tanstack/react-router';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import {
  AmountInput,
  Button,
  Checkbox,
  Combobox,
  ComboboxItem,
  CurrencyFlag,
  CurrencyPicker,
  DatePicker,
  DetailsCard,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Label,
  PageWrapper,
  Progress,
  Separator,
  toast,
} from '@agyt/client/shared/ui/components';
import {
  IconAlertCircle,
  IconArrowLeftRight,
  IconArrowRight,
  IconCheckCircle,
} from '@agyt/client/shared/ui/icons';
import { useUser } from '@agyt/client/web/core/user';
import {
  useCreateConversion,
  useGetFxConversionDates,
  useGetFxRates,
} from '@agyt/client/web/data-access/api';
import {
  ConversionDatesResponse,
  ConversionRateMetaResponse,
  ConversionResponse,
  CreateConversionPayloadBody,
  DetailedRatesResponse,
} from '@agyt/shared/types';
import {
  CurrencyDisplay,
  LocalDate,
  LocalNumber,
  Money,
} from '@agyt/shared/util/common';

const conversionDetailsOrderMap = {
  sellCurrency: 1,
  buyCurrency: 2,
  clientRate: 3,
  status: 4,
  createdAt: 5,
  settlementDate: 6,
  conversionDate: 7,
  shortReference: 8,
};

const enum CurrencyExchangeStep {
  'EXCHANGE_DETAILS',
  'REVIEW',
  'SUCCESS',
}

export const fixedSides = ['buy', 'sell'] as const;

const fixedSideEnum = z.enum(fixedSides, {
  message: 'common.validation.required',
});

const currencyExchangeForm = z.object({
  sellCurrency: z.string().min(1, {
    message: 'required',
  }),
  buyCurrency: z.string().min(1, {
    message: 'required',
  }),
  fixedSide: fixedSideEnum,
  amount: z.string().min(1, {
    message: 'required',
  }),
  conversionDate: z.string(),
});

export type CurrencyExchangeForm = z.infer<typeof currencyExchangeForm>;

export function CurrencyExchangePage() {
  const { t } = useTranslation('currencyExchange');
  const { locale } = useUser();
  const { mutateAsync: fetchFxRates } = useGetFxRates();
  const { mutateAsync: fetchFxConversionDates } = useGetFxConversionDates();
  const { mutateAsync: createConversion } = useCreateConversion();

  const [progress, setProgress] = useState(33);
  const [fxRatesData, setFxRatesData] = useState<DetailedRatesResponse>();
  const [inverse, setInverse] = useState<string>();
  const [fxRateMeta, setFxRateMeta] = useState<ConversionRateMetaResponse>();
  const [conversionUniqueRequestId, setConversionUniqueRequestId] = useState<
    string | null
  >(null);
  const [fxDatesData, setFxDatesData] = useState<ConversionDatesResponse>();
  const [conversionData, setConversionData] = useState<ConversionResponse>();
  const [quoteConsent, setQuoteConsent] = useState(false);
  const [quoteTime, setQuoteTime] = useState(20);
  const [quoteProgress, setQuoteProgress] = useState(100);
  const [step, setStep] = useState<CurrencyExchangeStep>(
    CurrencyExchangeStep.EXCHANGE_DETAILS,
  );

  const form = useForm<CurrencyExchangeForm>({
    resolver: zodResolver(currencyExchangeForm),
    defaultValues: {
      sellCurrency: '',
      buyCurrency: '',
      fixedSide: fixedSideEnum.enum.buy,
      amount: '',
      conversionDate: new Date().toISOString(),
    },
  });

  const sellCurrency = form.watch('sellCurrency');
  const buyCurrency = form.watch('buyCurrency');

  const areCurrenciesSelectedAndValid = useMemo(
    () => buyCurrency && sellCurrency && buyCurrency !== sellCurrency,
    [buyCurrency, sellCurrency],
  );

  useEffect(() => {
    async function getFxConversionDates() {
      if (areCurrenciesSelectedAndValid) {
        try {
          const dates = await fetchFxConversionDates({
            sellCurrency,
            buyCurrency,
            startDate: new Date().toISOString(),
            conversionPair: `${buyCurrency}${sellCurrency}`,
          });
          if (dates?.data) {
            setFxDatesData(dates.data);
            form.setValue('conversionDate', dates.data.firstConversionDate);
          }
        } catch (error) {
          // TODO parse and show validation errros
          console.error('Error fetching conversion dates:', error);
        }
      }
    }

    if (sellCurrency && buyCurrency && sellCurrency === buyCurrency) {
      toast.error(t('errors.currencyPair'));
      return;
    }

    getFxConversionDates();
  }, [
    sellCurrency,
    buyCurrency,
    fetchFxConversionDates,
    t,
    form,
    areCurrenciesSelectedAndValid,
  ]);

  const conversionDetails = useMemo(() => {
    if (!conversionData) {
      return null;
    }

    return Object.keys(conversionDetailsOrderMap)
      .map((key) => {
        if (key === 'sellCurrency') {
          return {
            key: t('labels.sell'),
            value: new Money({
              amount: +conversionData.clientSellAmount,
              currency: conversionData.sellCurrency,
              locale,
            }).format(),
          };
        }
        if (key === 'buyCurrency') {
          return {
            key: t('labels.buy'),
            value: new Money({
              amount: +conversionData.clientBuyAmount,
              currency: conversionData.buyCurrency,
              locale,
            }).format(),
          };
        }

        if (key === 'clientRate') {
          const inverseInfo = inverse
            ? ` (${t('labels.inverse')} ${inverse})`
            : '';
          return {
            key: t('labels.clientRate'),
            value: `${conversionData.clientRate}${inverseInfo} ${t('labels.on')} ${new LocalDate(
              {
                timestamp: conversionData.createdAt,
                locale,
              },
            ).format(DateTime.DATE_SHORT)}`,
          };
        }

        if (key === 'status') {
          return {
            key: t('labels.status'),
            value: t(`labels.fxStatus.${conversionData.status}`),
          };
        }

        if (
          key === 'createdAt' ||
          key === 'settlementDate' ||
          key === 'conversionDate'
        ) {
          return {
            key: t(`labels.${key}`),
            value: new LocalDate({
              timestamp: conversionData[key],
              locale,
            }).format(DateTime.DATE_SHORT),
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (conversionData as any)[key],
        };
      })
      .filter((item) => item.value);
  }, [conversionData, t, locale, inverse]);

  useEffect(() => {
    if (step === CurrencyExchangeStep.REVIEW) {
      if (quoteTime >= 0) {
        const interval = setInterval(() => {
          setQuoteTime((prevTime) => prevTime - 1);
          setQuoteProgress((prevProgress) => prevProgress - 5);
        }, 1000);

        return () => clearInterval(interval);
      }
    }
  }, [quoteTime, step]);

  async function getRates() {
    try {
      const data = form.getValues();
      const fxRatesData = await fetchFxRates(data);

      setFxRatesData(fxRatesData.data.rates);
      setInverse(fxRatesData.data.inverse);
      setFxRateMeta(fxRatesData.data.meta);
      setConversionUniqueRequestId(uuidv4());

      setStep(CurrencyExchangeStep.REVIEW);
      setProgress(66);
    } catch (error) {
      //@TODO: Handle error parse + show validation errors
      const errors = (error as any).response.data.errors;
    }
  }

  async function onConfirmFx() {
    try {
      if (!conversionUniqueRequestId || !fxRateMeta) {
        return;
      }

      const { sellCurrency, buyCurrency, fixedSide, conversionDate } =
        form.getValues();
      const data: CreateConversionPayloadBody = {
        sellCurrency,
        buyCurrency,
        fixedSide,
        conversionDate,
        termAgreement: quoteConsent,
        clientBuyAmount: fxRatesData?.clientBuyAmount,
        clientSellAmount: fxRatesData?.clientSellAmount,
        uniqueRequestId: conversionUniqueRequestId,
        inverse: inverse ?? '',
        meta: fxRateMeta,
      };
      const conversion = await createConversion(data);

      setConversionData(conversion.data);
      setStep(CurrencyExchangeStep.SUCCESS);
      setProgress(100);
    } catch (error) {
      //@TODO: Handle error parse + show validation errors
      const errors = (error as any).response.data.errors;
    }
  }

  function onSwapCurrencies() {
    const [sellCurrency, buyCurrency] = form.getValues([
      'sellCurrency',
      'buyCurrency',
    ]);
    form.setValue('sellCurrency', buyCurrency);
    form.setValue('buyCurrency', sellCurrency);
  }

  function onReset() {
    setQuoteConsent(false);
    setQuoteProgress(100);
    setQuoteTime(20);
    setProgress(33);
    setFxDatesData(undefined);
    setFxRatesData(undefined);
    setInverse(undefined);
    setConversionData(undefined);
    form.reset();
    setStep(CurrencyExchangeStep.EXCHANGE_DETAILS);
  }

  const disabledDays = useMemo(() => {
    return (
      fxDatesData && [
        { before: new Date(fxDatesData.firstConversionDate) },
        ...Object.keys(fxDatesData.invalidConversionDates).map(
          (date) => new Date(date),
        ),
      ]
    );
  }, [fxDatesData]);

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="flex h-full w-[555px] flex-col">
        {step !== CurrencyExchangeStep.SUCCESS && (
          <header className="flex flex-col items-center justify-between">
            <div className="relative w-full">
              <Progress value={progress} />
            </div>
          </header>
        )}
        <div className="h-full">
          {step === CurrencyExchangeStep.EXCHANGE_DETAILS && (
            <section>
              <div className="mb-16 mt-14 w-full">
                <h2 className="text-3xl font-medium">{t('details.title')}</h2>
              </div>
              <Form {...form}>
                <form className="mt-16" onSubmit={form.handleSubmit(getRates)}>
                  <div className="flex flex-col gap-5">
                    <div className="flex w-full items-start justify-between gap-2">
                      <div className="w-full">
                        <FormField
                          control={form.control}
                          name="sellCurrency"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                                {t('labels.sell')}
                              </FormLabel>
                              <FormControl>
                                <CurrencyPicker
                                  value={field.value}
                                  onChange={(currency) =>
                                    field.onChange(currency?.value)
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>

                      <Button
                        type="button"
                        variant="subtleDark"
                        onClick={onSwapCurrencies}
                        className="mt-6"
                        disabled={!areCurrenciesSelectedAndValid}
                      >
                        <IconArrowLeftRight className="h-6 w-6" />
                      </Button>

                      <div className="w-full">
                        <FormField
                          control={form.control}
                          name="buyCurrency"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                                {t('labels.buy')}
                              </FormLabel>
                              <FormControl>
                                <CurrencyPicker
                                  value={field.value}
                                  onChange={(currency) =>
                                    field.onChange(currency?.value)
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <FormLabel className="mb-0 text-sm font-medium text-slate-900">
                        {t('labels.amount')}
                      </FormLabel>
                      <div className="flex">
                        <FormField
                          control={form.control}
                          name="fixedSide"
                          render={({ field }) => (
                            <FormItem className="w-1/3">
                              <FormControl>
                                <Combobox<ComboboxItem>
                                  items={fixedSides.map((side) => ({
                                    label: t(`labels.${side}`),
                                    value: side,
                                  }))}
                                  selectedItem={field as any}
                                  onSelect={(fixedSide) =>
                                    field.onChange(fixedSide?.value)
                                  }
                                  label=""
                                  emptyLabel=""
                                  placeholder=""
                                  disableSearch
                                  className="rounded-r-none border-r-0"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="amount"
                          render={({ field }) => (
                            <FormItem className="w-2/3">
                              <FormControl>
                                <AmountInput
                                  {...field}
                                  suffix={
                                    form.getValues('fixedSide') ===
                                    fixedSideEnum.enum.sell
                                      ? form.getValues('sellCurrency')
                                      : form.getValues('buyCurrency')
                                  }
                                  className="rounded-l-none"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>

                    <FormField
                      control={form.control}
                      name="conversionDate"
                      render={({ field }) => (
                        <FormItem className="mb-4">
                          <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                            {t('labels.conversionDate')}
                          </FormLabel>
                          <FormControl>
                            <div>
                              <DatePicker
                                className="w-full"
                                disabled={disabledDays}
                                disabledButton={!areCurrenciesSelectedAndValid}
                                date={field.value}
                                onSelect={field.onChange}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="mt-16 flex gap-4">
                    <Button
                      type="submit"
                      className="flex-1"
                      disabled={!areCurrenciesSelectedAndValid}
                    >
                      {t('actions.getAQuote', { ns: 'common' })}
                    </Button>
                  </div>
                </form>
              </Form>
            </section>
          )}
          {step === CurrencyExchangeStep.REVIEW && (
            <section>
              <div className="mb-16 mt-14 w-full">
                <h2 className="text-3xl font-medium">{t('review.title')}</h2>
              </div>
              <div className="mt-16 flex flex-col">
                <div className="flex w-full flex-col gap-4 rounded-lg border border-slate-200 bg-white p-4">
                  <div className="text-sm font-medium leading-4 text-slate-900">
                    {t('review.subtitle')}
                  </div>
                  <div className="flex items-baseline gap-6">
                    <div className="text-xl font-medium leading-7 text-slate-900">
                      {fxRatesData &&
                        new LocalNumber({
                          value: +fxRatesData.clientRate,
                          locale,
                        }).formatAsRate()}
                    </div>
                    <div className="text-sm text-slate-500">
                      <span>{t('labels.inverse')}:</span>&nbsp;
                      <span className="font-medium">
                        {inverse &&
                          new LocalNumber({
                            value: +inverse,
                            locale,
                          }).formatAsRate()}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mb-6 mt-4 flex gap-4">
                  <div className="flex w-1/2 flex-col gap-4 rounded-lg border border-slate-200 bg-white p-4">
                    <div className="text-sm font-medium leading-4 text-slate-900">
                      {t('review.sellSectionTitle')}
                    </div>
                    <div className="flex items-center gap-4">
                      {fxRatesData?.clientSellCurrency && (
                        <CurrencyFlag
                          currency={Money.getAsSupportedCurrency(
                            fxRatesData?.clientSellCurrency,
                          )}
                          alt={fxRatesData?.clientSellCurrency}
                          className="h-[23px] w-[23px]"
                        />
                      )}
                      <div className="leading- text-xl font-medium text-slate-900">
                        {fxRatesData &&
                          new Money({
                            amount: +fxRatesData.clientSellAmount,
                            currency: fxRatesData.clientSellCurrency,
                            locale,
                            currencyDisplay: CurrencyDisplay.NONE,
                          }).format()}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-col gap-4 rounded-lg border border-slate-200 bg-white p-4">
                    <div className="text-sm font-medium leading-4 text-slate-900">
                      {t('review.buySectionTitle')}
                    </div>
                    <div className="flex items-center gap-4">
                      {fxRatesData?.clientBuyCurrency && (
                        <CurrencyFlag
                          currency={Money.getAsSupportedCurrency(
                            fxRatesData?.clientBuyCurrency,
                          )}
                          alt={fxRatesData?.clientBuyCurrency}
                          className="h-[23px] w-[23px]"
                        />
                      )}
                      <div className="text-xl font-medium text-slate-900">
                        {fxRatesData &&
                          new Money({
                            amount: +fxRatesData.clientBuyAmount,
                            currency: fxRatesData.clientBuyCurrency,
                            locale,
                            currencyDisplay: CurrencyDisplay.NONE,
                          }).format()}
                      </div>
                    </div>
                  </div>
                </div>

                {fxRatesData && (
                  <DetailsCard
                    data={[
                      {
                        key: t('labels.settlementDate'),
                        value: new LocalDate({
                          timestamp: fxRatesData.settlementCutOffTime,
                          locale,
                        }).format(DateTime.DATE_SHORT),
                      },
                      {
                        key: t('labels.conversionDate'),
                        value: new LocalDate({
                          timestamp: form.getValues('conversionDate'),
                          locale,
                        }).format(DateTime.DATE_SHORT),
                      },
                    ]}
                  />
                )}

                <Separator className="my-10" />

                <Label htmlFor="quote-consent" className="hover:cursor-pointer">
                  <div className="flex w-full items-center rounded-md border border-slate-300 bg-white p-3">
                    <Checkbox
                      id="quote-consent"
                      checked={quoteConsent}
                      onCheckedChange={() => setQuoteConsent(!quoteConsent)}
                      className="mr-2"
                    />
                    {t('actions.confirmQuote')}
                  </div>
                </Label>

                <div className="mt-10 flex flex-col">
                  {quoteTime >= 0 && quoteProgress <= 100 ? (
                    <>
                      <div className="text-base font-medium text-gray-900">
                        {t('quoteExpire.quoteWithCount', {
                          count: quoteTime,
                        })}
                      </div>
                      <Progress
                        value={quoteProgress}
                        className="mt-4 h-1 w-full"
                      />
                    </>
                  ) : (
                    <div className="flex items-center gap-2">
                      <IconAlertCircle className="h-6 w-6 text-red-700" />
                      <div className="text-sm text-red-700">
                        {t('review.expiredSectionTitle')}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-6">
                {quoteTime < 0 && (
                  <Button
                    className="mb-4 w-full"
                    onClick={() => {
                      getRates();
                      setQuoteConsent(false);
                      setQuoteProgress(100);
                      setQuoteTime(20);
                    }}
                  >
                    {t('actions.reQuote')}
                  </Button>
                )}
                <div className="flex gap-4">
                  <Button
                    variant="subtleDark"
                    className="flex-1"
                    onClick={() => {
                      setQuoteConsent(false);
                      setQuoteProgress(100);
                      setQuoteTime(20);
                      setProgress(33);
                      setStep(CurrencyExchangeStep.EXCHANGE_DETAILS);
                    }}
                  >
                    {t('actions.back', { ns: 'common' })}
                  </Button>
                  <Button
                    className="flex-1"
                    disabled={!quoteConsent || quoteTime < 0}
                    onClick={onConfirmFx}
                  >
                    {t('actions.confirm', { ns: 'common' })}
                  </Button>
                </div>
              </div>
            </section>
          )}
          {step === CurrencyExchangeStep.SUCCESS && (
            <section>
              <div className="my-14 flex w-full flex-col items-center gap-6">
                <IconCheckCircle className="h-6 w-6" />
                <h2 className="text-xl font-medium">{t('success.title')}</h2>
                {conversionData && (
                  <div className="flex items-center gap-6">
                    <div className="flex items-center gap-2">
                      <CurrencyFlag
                        currency={Money.getAsSupportedCurrency(
                          conversionData.sellCurrency,
                        )}
                        alt={conversionData.sellCurrency}
                        className="h-[23px] w-[23px]"
                      />
                      <div className="text-lg font-medium text-slate-900">
                        {new Money({
                          amount: +conversionData.clientSellAmount,
                          currency: conversionData?.sellCurrency,
                          locale,
                          currencyDisplay: CurrencyDisplay.NONE,
                        }).format()}
                      </div>
                    </div>

                    <div className="flex h-8 w-8 items-center justify-center rounded-md bg-slate-200">
                      <IconArrowRight className="h-4 w-4 text-slate-900" />
                    </div>

                    <div className="flex items-center gap-2">
                      <CurrencyFlag
                        currency={Money.getAsSupportedCurrency(
                          conversionData.buyCurrency,
                        )}
                        alt={conversionData.buyCurrency}
                        className="h-[23px] w-[23px]"
                      />
                      <div className="text-lg font-medium text-slate-900">
                        {new Money({
                          amount: +conversionData.clientBuyAmount,
                          currency: conversionData.buyCurrency,
                          locale,
                          currencyDisplay: CurrencyDisplay.NONE,
                        }).format()}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {conversionDetails && <DetailsCard data={conversionDetails} />}

              <div className="mt-10 text-base leading-7 text-slate-500">
                {t('success.body')}
              </div>

              <div className="mt-16 flex flex-col gap-4">
                <div className="flex w-full gap-4">
                  <Button variant="subtleDark" className="flex-1" asChild>
                    <Link to="/pay">{t('actions.payment')}</Link>
                  </Button>
                  <Button
                    variant="subtleDark"
                    className="flex-1"
                    onClick={onReset}
                  >
                    {t('actions.currencyExchange')}
                  </Button>
                </div>
                <Button asChild>
                  <Link to="/">{t('actions.viewDashboard')}</Link>
                </Button>
              </div>
            </section>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

export default CurrencyExchangePage;
