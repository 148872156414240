import { CcTransactionAction } from '@agyt/shared/types';
import { Combobox, ComboboxItem } from './combobox';
import { useTranslation } from 'react-i18next';

export interface TransactionType extends ComboboxItem {
  value: CcTransactionAction;
  label: string;
}

export interface TransactionTypePickerProps {
  value?: string;
  onChange: (value: TransactionType | undefined) => void;
}

// @TODO we should probably get this from an Enum somewhere
const TransactionTypes = [
  'funding',
  'conversion',
  'payment',
  'payment_failure',
  'manual_intervention',
  'manual_transaction',
  'top_up_fee',
  'transfer',
  'conversion_deposit',
  'deposit_refund',
  'payment_fee',
  'payment_unrelease',
  'margin',
];

export function TransactionTypePicker({
  value,
  onChange,
}: TransactionTypePickerProps) {
  const { t } = useTranslation('common');

  const types = TransactionTypes.map((type) => ({
    value: type,
    label: t(`values.action.${type}`, { ns: 'transactions:details' }),
  }));

  return (
    <Combobox<TransactionType>
      items={types}
      label={t('transactionType.label')}
      emptyLabel={t('transactionType.empty')}
      placeholder={t('transactionType.placeholder')}
      selectedItem={types.find((type) => type.value === value)}
      onSelect={onChange}
      disableSearch
    />
  );
}

export default TransactionTypePicker;
