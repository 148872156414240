import { useTranslation } from 'react-i18next';
import { Button } from './button';
import { Link } from '@tanstack/react-router';
import { SupportedCurrency } from '@agyt/shared/util/common';

export function AccountListItem({
  name,
  balance,
  children,
  currency,
}: {
  name: string;
  balance: string;
  currency: SupportedCurrency;
  children: React.ReactNode;
}) {
  const { t } = useTranslation('common');
  return (
    <div className="flex h-[77px] rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div className="mr-5">{children}</div>
          <Link to="/transactions" search={{ currency }}>
            <div className="mr-10 text-base leading-7 text-slate-500">
              {name}
            </div>
          </Link>
          <div className="text-xl font-medium text-slate-900">{balance}</div>
        </div>

        <div className="flex w-[150px] gap-3">
          <Button variant="subtle" size="sm">
            <Link to="/pay">{t('actions.pay')}</Link>
          </Button>
          <Button variant="subtle" size="sm">
            <Link to="/currency-exchange">{t('actions.exchange')}</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AccountListItem;
