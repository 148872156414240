import { Link, useParams } from '@tanstack/react-router';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  DetailsCard,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  PageWrapper,
  Skeleton,
  toast,
  TransactionStatusBadge,
} from '@agyt/client/shared/ui/components';
import { useUser } from '@agyt/client/web/core/user';
import {
  useCancelConversion,
  useCancelPayment,
  useGetTransactionDetails,
} from '@agyt/client/web/data-access/api';
import { TransactionDetailsResponse } from '@agyt/shared/types';
import {
  getCountryNameFromCode,
  LocalDate,
  Money,
} from '@agyt/shared/util/common';

const transactionDetailOrderMap = {
  action: 0,
  amount: 1,
  reason: 2,
  status: 3,
  relatedEntityShortReference: 4,
  createdAt: 5,
  settledAt: 6,
  completedAt: 7,
};

const beneficiaryDetailsOrderMap = {
  beneficiaryType: 0,
  iban: 1,
  bic: 2,
  beneficiaryAddress: 3,
  beneficiaryCity: 4,
  beneficiaryCountry: 5,
};

const conversionDetailsOrderMap = {
  buyCurrency: 0,
  sellCurrency: 1,
  status: 2,
  fixedSide: 3,
  clientRate: 4,
  createdAt: 5,
  settlementDate: 6,
  conversionDate: 7,
};

function CancelTransactionContainer({
  transactionDetails,
}: {
  transactionDetails: TransactionDetailsResponse;
}) {
  const { t } = useTranslation('transactions:details');
  const { locale } = useUser();
  const { mutateAsync: cancelConversion } = useCancelConversion({
    transaction: transactionDetails?.transaction,
  });
  const { mutateAsync: cancelPayment } = useCancelPayment({
    transaction: transactionDetails?.transaction,
  });

  async function cancelTransaction() {
    if (transactionDetails.transaction?.action === 'payment') {
      try {
        const paymentId: string =
          transactionDetails?.payment?.id ||
          transactionDetails?.transaction?.relatedEntityId;
        await cancelPayment({
          id: paymentId,
        });
        toast.success(t('cancel.success.title'));
      } catch (err) {
        toast.error(t('errors.generic', { ns: 'common' }));
      }
    } else if (transactionDetails.transaction?.action === 'conversion') {
      try {
        const conversionId: string =
          transactionDetails?.conversion?.id ||
          transactionDetails?.transaction?.relatedEntityId;
        await cancelConversion({
          id: conversionId,
        });
        toast.success(t('cancel.success.title'));
      } catch (err) {
        toast.error(t('errors.generic', { ns: 'common' }));
      }
    }
  }

  const title = useMemo(() => {
    if (transactionDetails.transaction?.action === 'payment') {
      return t('cancel.payment.title');
    }
    return t('cancel.conversion.title');
  }, [transactionDetails, t]);

  const description = useMemo(() => {
    if (transactionDetails.transaction?.action === 'payment') {
      return t('cancel.payment.description');
    }

    return t('cancel.conversion.description');
  }, [transactionDetails, t]);

  const quoteDescription = useMemo(() => {
    const quote = transactionDetails.conversionCancellationQuote;
    const amount = quote
      ? new Money({
          amount: +quote.amount,
          currency: quote.currency,
          locale,
        }).format()
      : '';
    return amount
      ? t('cancel.conversion.quoteDescription', { quote: amount })
      : '';
  }, [transactionDetails, t, locale]);

  const cancelTransactionCopy = useMemo(() => {
    if (transactionDetails.transaction?.action === 'payment') {
      return t('cancel.actions.cancelPayment');
    }
    return t('cancel.actions.cancelConversion');
  }, [t, transactionDetails]);

  return (
    <Dialog>
      <DialogTrigger asChild onClick={(e) => e.stopPropagation()}>
        <Button variant="subtleDestructive">
          {t('actions.cancel', { ns: 'common' })}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {description}
          {quoteDescription && (
            <span className="mt-2 inline-block">{quoteDescription}</span>
          )}
        </DialogDescription>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild onClick={(e) => e.stopPropagation()}>
            <Button type="button" variant="outline">
              {t('actions.close', { ns: 'common' })}
            </Button>
          </DialogClose>
          <DialogClose asChild onClick={(e) => e.stopPropagation()}>
            <Button variant="subtleDestructive" onClick={cancelTransaction}>
              {cancelTransactionCopy}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function TransactionDetailsPage() {
  const { id } = useParams({ strict: false });
  const { t } = useTranslation('transactions:details');
  const { locale } = useUser();
  const { data, isLoading } = useGetTransactionDetails({
    id,
  });
  const transactionDetails = useMemo(() => data?.data, [data]);

  const title = useMemo(() => {
    if (transactionDetails?.transaction?.action === 'payment') {
      return (
        transactionDetails.paymentBeneficiaryGroup?.name ??
        transactionDetails?.transaction.relatedEntityShortReference
      );
    } else if (transactionDetails?.transaction?.action === 'conversion') {
      return `${transactionDetails.conversion?.buyCurrency} ${t('labels.to')} ${transactionDetails.conversion?.sellCurrency}`;
    }
    return transactionDetails?.transaction?.relatedEntityShortReference;
  }, [transactionDetails, t]);

  const transactionData = useMemo(() => {
    const transaction = transactionDetails?.transaction;
    if (!transaction) {
      return null;
    }

    return Object.keys(transactionDetailOrderMap)
      .map((key, value) => {
        if (key === 'amount') {
          return {
            key: t(`labels.${key}`),
            value: new Money({
              amount: +transaction.amount,
              currency: transaction.currency,
              locale,
            }).format(),
          };
        }

        if (
          key === 'createdAt' ||
          key === 'settledAt' ||
          key === 'completedAt'
        ) {
          if (!transaction[key]) {
            return {
              key: t(`labels.${key}`),
              value: '',
            };
          }

          return {
            key: t(`labels.${key}`),
            value: new LocalDate({
              timestamp: transaction[key],
              locale,
            }).format(),
          };
        }

        if (key === 'status') {
          return {
            key: t(`labels.${key}`),
            value: t(`values.status.${transaction.status}`),
          };
        }

        if (key === 'action') {
          return {
            key: t(`labels.${key}`),
            value: t(`values.action.${transaction.action}`),
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (transaction as any)[key],
        };
      })
      .filter((item) => item.value);
  }, [transactionDetails, locale, t]);

  const beneficiaryDetails = useMemo(() => {
    const beneficiaryData = transactionDetails?.paymentBeneficiary;
    if (!beneficiaryData) {
      return null;
    }
    return Object.keys(beneficiaryDetailsOrderMap)
      .map((key) => {
        if (key === 'beneficiaryType') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: t(`values.${beneficiaryData[key]}`, {
              ns: 'beneficiaries:details',
            }),
          };
        }

        if (key === 'beneficiaryAddress') {
          return {
            key: t(`labels.beneficiaryDetails.${key}`),
            value: `${(beneficiaryData[key] as unknown as string[]).join(',')}`,
          };
        }

        if (key === 'beneficiaryCountry') {
          return {
            key: t(`labels.beneficiaryDetails.${key}`),
            value: getCountryNameFromCode(beneficiaryData[key]),
          };
        }

        return {
          key: t(`labels.beneficiaryDetails.${key}`),
          value: (beneficiaryData as any)[key],
        };
      })
      .filter((item) => item.value);
  }, [transactionDetails, t]);

  const conversionDetails = useMemo(() => {
    const conversion = transactionDetails?.conversion;
    if (!conversion) {
      return null;
    }

    return Object.keys(conversionDetailsOrderMap).map((key) => {
      if (
        key === 'createdAt' ||
        key === 'settlementDate' ||
        key === 'conversionDate'
      ) {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: new LocalDate({
            timestamp: conversion[key],
            locale,
          }).format(),
        };
      }

      if (key === 'status') {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: t(`labels.fxStatus.${conversion.status}`, {
            ns: 'currencyExchange',
          }),
        };
      }

      if (key === 'fixedSide') {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: t(`labels.conversionDetails.${conversion[key]}`),
        };
      }

      if (key === 'buyCurrency') {
        return {
          key: t(`labels.conversionDetails.buy`),
          value: new Money({
            amount: +conversion.clientBuyAmount,
            currency: conversion.buyCurrency,
            locale,
          }).format(),
        };
      }

      if (key === 'sellCurrency') {
        return {
          key: t(`labels.conversionDetails.sell`),
          value: new Money({
            amount: +conversion.clientSellAmount,
            currency: conversion.sellCurrency,
            locale,
          }).format(),
        };
      }

      if (key === 'clientRate') {
        const inverse = transactionDetails?.conversionInverse;
        const inverseLabel = inverse
          ? `(${t('labels.conversionDetails.inverse')}: ${inverse.toFixed(4)})`
          : '';

        return {
          key: t(`labels.conversionDetails.${key}`),
          value: `${conversion.clientRate} ${inverseLabel}`,
        };
      }

      return {
        key: t(`labels.conversionDetails.${key}`),
        value: (conversion as any)[key],
      };
    });
  }, [transactionDetails, locale, t]);

  const isCancellable = useMemo(() => {
    return (
      (transactionDetails?.conversion &&
        transactionDetails?.conversion?.status === 'awaiting_funds') || // TODO ENUM
      (transactionDetails?.payment &&
        transactionDetails?.payment?.status === 'ready_to_send') // TODO ENUM
    );
  }, [transactionDetails]);

  if (isLoading || !transactionDetails) {
    return (
      <PageWrapper className="flex flex-col gap-8">
        <Skeleton className="h-8 w-40 rounded-full" />
        <Skeleton className="h-8 w-80 rounded-full" />

        <Skeleton className="mt-16 h-8 w-80 rounded-full" />
        <div className="flex flex-col gap-2">
          <Skeleton className="h-8 w-96 rounded-full" />
          <Skeleton className="h-8 w-96 rounded-full" />
          <Skeleton className="h-8 w-96 rounded-full" />
          <Skeleton className="h-8 w-96 rounded-full" />
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper className="flex flex-col">
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              <Link to="/transactions" className="cursor-pointer">
                {t('title', { ns: 'transactions' })}
              </Link>
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <header className="mb-10 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{title}</h1>
          {transactionDetails.transaction?.status && (
            <TransactionStatusBadge
              status={transactionDetails?.transaction?.status}
            />
          )}
        </div>
        {transactionDetails && isCancellable && (
          <div className="flex items-center gap-3">
            <CancelTransactionContainer
              transactionDetails={transactionDetails}
            />
          </div>
        )}
      </header>
      <section className="mb-10 w-1/2">
        <h3 className="mb-4 text-xl font-medium">{t('receipt.title')}</h3>
        {transactionData && <DetailsCard data={transactionData} />}
      </section>
      {transactionDetails?.paymentBeneficiaryGroup && (
        <section className="mb-10 w-1/2">
          <h3 className="mb-4 text-xl font-medium">
            {t(`beneficiaryDetails.title`, {
              ns: 'beneficiaries:details',
            })}
          </h3>
          <DetailsCard
            data={[
              {
                key: t(`labels.beneficiaryDetails.name`),
                value: transactionDetails?.paymentBeneficiaryGroup?.name,
                href: `/beneficiaries/${transactionDetails?.paymentBeneficiaryGroup?.id}`,
              },
            ]}
          />
        </section>
      )}
      {beneficiaryDetails && (
        <section className="mb-10 w-1/2">
          <h3 className="mb-4 text-xl font-medium">
            {t(`bankAccountDetails.title`, {
              ns: 'beneficiaries:details',
            })}
          </h3>
          <DetailsCard data={beneficiaryDetails} />
        </section>
      )}

      {conversionDetails && (
        <section className="mb-10 w-1/2">
          <h3 className="mb-4 text-xl font-medium">{t('conversion.title')}</h3>
          <DetailsCard data={conversionDetails} />
        </section>
      )}
      {transactionDetails?.paymentTracking && (
        <pre className="max-h-96 overflow-auto rounded bg-gray-100 p-4">
          {JSON.stringify(transactionDetails?.paymentTracking, null, 2)}
        </pre>
      )}
    </PageWrapper>
  );
}

export default TransactionDetailsPage;
