import { flexRender, Table as TanStackTable } from '@tanstack/react-table';

import { cn } from '@agyt/client/shared/util/theme';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './table';

export function DataTable<T>({
  table,
  onRowClick,
  cellClassName = '',
}: {
  table: TanStackTable<T>;
  onRowClick: (Row: T) => void;
  cellClassName?: string;
}) {
  const columns = table
    .getHeaderGroups()[0]
    .headers.map((header) => header.column.columnDef);

  return (
    <section className="mt-5 rounded-md border border-slate-300 bg-white">
      <Table className="rounded-md bg-white leading-6 [&_th]:text-slate-400">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className="hover:bg-white">
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="[&_tr]:text-slate-900">
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                className="cursor-pointer"
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                onClick={(e) => {
                  e.stopPropagation();
                  onRowClick(row.original);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className={cn(cellClassName)}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </section>
  );
}

export default DataTable;
